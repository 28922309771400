<template>
  <div class="Download">
    <button class="btn" @click="getUrl" :disabled="disabled">下载合同</button>
  </div>
</template>

<script>
import { Toast } from 'vant';
import OSS from 'ali-oss';
import { getOssToken } from '../utils/getOssToken';

export default {
  name: 'Result',
  data() {
    return {
      code: '',
      clientOSS: {},
      loading: null,
      disabled: false,
    };
  },
  methods: {
    async getUrl() {
      this.disabled = true;
      this.loading = Toast.loading({
        duration: 0,
        message: '请稍候...',
        forbidClick: true,
      });
      const data = await this.$axios.post(
        '/tax/wechat/user/selectCarContract',
        {
          code: this.code,
        }
      );
      if (data.success && data.data) {
        this.getFile('/ling_gong' + data.data);
      } else {
        this.disabled = false;
        this.loading.clear();
      }
    },
    async getFile(filename) {
      const url = await this.clientOSS.signatureUrl(filename, {
        'content-disposition': `attachment; filename=${encodeURIComponent(
          filename
        )}`,
      });
      window.location.href = url;
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
  },
  created() {
    let code = this.$route.query.code;
    this.code = code;

    this.getToken();
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';
.Download {
  min-height: 100vh;
  background: #fff;
  padding-top: 70%;
  box-sizing: border-box;

  .btn {
    display: block;
    width: 3.8rem;
    height: 0.44rem;
    background-color: $blue;
    margin: 0 auto;
    padding: 0;
    border: none;
    border-radius: 0.22rem;
    color: #fff;
    font-size: 0.17rem;
    // box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);
    box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
    &:disabled {
      background: $gray;
    }
  }
}
</style>
