import Vue from 'vue';
import { Toast } from 'vant';
import axios from 'axios';
import qs from 'qs';

Vue.use(Toast);

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.common['appid'] = 3;

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const success = res.data.success;
    if (success) {
      return res.data;
    } else {
      Toast(res.data.rtnMessage);
      // return Promise.reject(res.data);
      return res.data;
    }
  },
  (error) => {
    console.log(error);
    if (String(error).includes('timeout')) {
      // Toast.fail('请求超时');
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
