<template>
  <div class="Simple">
    <van-nav-bar class="header-bar" title="签约" fixed />

    <div class="form">
      <div class="item van-hairline--bottom">
        <div class="label">姓名</div>
        <input
          type="text"
          class="text"
          v-model="form.name"
          placeholder="请输入姓名"
        />
      </div>
      <div class="item van-hairline--bottom">
        <div class="label">身份证号</div>
        <input
          type="text"
          class="text"
          v-model="form.idNo"
          placeholder="请输入身份证号"
        />
      </div>
      <van-button
        class="submit"
        type="info"
        block
        round
        :disabled="disabled || submiting"
        @click="handleSubmit"
        >确 定</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { NavBar, Toast } from 'vant';

Vue.use(NavBar).use(Toast);

export default {
  name: 'New',
  data() {
    return {
      form: {
        name: '',
        idNo: '',
      },
      submiting: false,
    };
  },
  created() {},
  computed: {
    disabled() {
      return !this.form.name || !this.form.idNo;
    },
    ...mapState({
      taskId: (state) => state.taskId,
      color: (state) => state.color,
    }),
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async handleSubmit() {
      this.submiting = true;
      const data = await this.$axios.post('/tax/wechat/user/hqqueryUserInfo', {
        name: this.form.name.trim(),
        idNo: this.form.idNo.trim(),
      });
      if (data.success) {
        if (data.data) {
          Toast.loading({
            message: '合同生成中',
            forbidClick: true,
          });
          this.getUrl(data.data.accountId);
        } else {
          this.$router.push({
            name: 'standard',
            query: { taskId: this.taskId, color: this.color, fromNew: 1 },
          });
        }
      }
      this.submiting = false;
    },
    async getUrl(accountId) {
      const data = await this.$axios.post('/tax/wechatController/doEleSign', {
        accountId,
        taskId: this.taskId,
      });
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.$router.replace('result');
        }
      }
      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';

.Simple {
  min-height: calc(100vh - 0.46rem);
  padding-top: 0.46rem;
  background: #fff;

  .form {
    // padding: 0.36rem 0.42rem;

    .item {
      position: relative;
      margin-left: 0.16rem;
      padding: 0.13rem 0;

      &.van-hairline--bottom::after {
        border-color: $gray;
      }

      .label {
        height: 0.2rem;
        line-height: 0.2rem;
        margin-bottom: 0.05rem;
        color: #7d7f93;
      }

      .text {
        width: 3.48rem;
        height: 0.3rem;
        line-height: 0.3rem;
        padding: 0;
        border: none;
        color: #000;
        font-size: 0.18rem;

        &::placeholder {
          color: #b0b0b0;
        }

        &.code {
          width: 2.8rem;
        }
      }

      .icon-clear {
        position: absolute;
        top: 0.44rem;
        right: 0.16rem;
        color: #969799;
        font-size: 0.18rem;
      }

      .btn-send {
        position: absolute;
        top: 0.38rem;
        right: 0;
        height: 0.3rem;
        line-height: 0.3rem;
        padding: 0 0.16rem;
        border: none;
        color: $blue;
        font-size: 0.15rem;

        &.van-button--disabled {
          color: $gray;
          opacity: 1;
        }
      }

      .count-down {
        position: absolute;
        top: 0.38rem;
        right: 0;
        height: 0.3rem;
        line-height: 0.3rem;
        padding: 0 0.16rem;
        color: #adafb9;
        font-size: 0.15rem;
      }
    }

    .submit {
      width: 3.44rem;
      margin: 0.48rem auto 0;
      background: $blue;
      border-color: $blue;
      font-size: 0.17rem;
      font-weight: 500;
      box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);

      @at-root .theme-orange & {
        background: $orange;
        border-color: $orange;
        box-shadow: 0 0.03rem 0.1rem rgba($orange, 0.3);
      }

      &.van-button--disabled {
        background: $gray;
        border-color: $gray;
        box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }
}
</style>
