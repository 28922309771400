import Vue from 'vue';
import Router from 'vue-router';
import Standard from '../pages/Standard.vue';
import Simple from '../pages/Simple.vue';
import Result from '../pages/Result.vue';
import Vehicle from '../pages/Vehicle.vue';
import Download from '../pages/Download.vue';
import Resign from '../pages/Resign.vue';
import Three from '../pages/Three.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'standard',
      component: Standard,
    },
    {
      path: '/simple',
      name: 'simple',
      component: Simple,
    },
    {
      path: '/result',
      name: 'result',
      component: Result,
    },
    {
      path: '/vehicle',
      name: 'vehicle',
      component: Vehicle,
    },
    {
      path: '/d',
      name: 'download',
      component: Download,
    },
    {
      path: '/resign',
      name: 'resign',
      component: Resign,
    },
    {
      path: '/three',
      name: 'three',
      component: Three,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
