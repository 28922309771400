<template>
  <div class="Resign">
    <van-nav-bar class="header-bar" title="离职" fixed />

    <div class="form">
      <div class="item van-hairline--bottom">
        <div class="label">姓名</div>
        <input
          type="text"
          class="text"
          v-model="form.name"
          placeholder="请输入姓名"
        />
      </div>
      <div class="item van-hairline--bottom">
        <div class="label">身份证号</div>
        <input
          type="text"
          class="text"
          v-model="form.idNo"
          placeholder="请输入身份证号"
        />
      </div>
      <div class="item van-hairline--bottom">
        <div class="label">手机号码</div>
        <input
          type="text"
          class="text"
          v-model="form.phone"
          placeholder="请输入手机号码"
          maxlength="11"
        />
      </div>
      <div class="item van-hairline--bottom">
        <div class="label">验证码</div>
        <input
          type="text"
          class="text smsCode"
          v-model="form.smsCode"
          placeholder="请输入验证码"
          maxlength="4"
        />
        <van-button
          v-if="!counting"
          class="btn-send"
          plain
          :disabled="form.phone.length < 11"
          @click="getCode"
          >获取验证码</van-button
        >
        <span v-if="counting" class="count-down">{{ seconds }}s后可重发</span>
      </div>
      <van-button
        class="submit"
        type="info"
        block
        round
        :disabled="disabled || submiting"
        @click="handleSubmit"
        >确 定</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { NavBar, Toast } from 'vant';

Vue.use(NavBar).use(Toast);

export default {
  name: 'Resign',
  data() {
    return {
      positionNo: '',
      form: {
        name: '',
        idNo: '',
        phone: '',
        smsCode: '',
        smsCodeId: '',
      },
      seconds: 60,
      counting: false,
      submiting: false,
    };
  },
  computed: {
    ...mapState({
      taskId: (state) => state.taskId,
    }),
    disabled() {
      return (
        !this.form.name ||
        !this.form.idNo ||
        !this.form.phone ||
        !this.form.smsCode
      );
    },
  },
  created() {
    this.positionNo = this.$route.query.no;
  },
  methods: {
    async getCode() {
      let reg = /^1\d{10}$/;
      if (!reg.test(this.form.phone)) {
        Toast({
          message: '请输入正确格式的手机号码',
          position: 'bottom',
        });
        return;
      }
      this.counting = true;
      this.seconds = 60;
      this.form.smsCodeId = '';
      let t1 = setInterval(() => {
        if (this.seconds < 1) {
          this.counting = false;
          clearInterval(t1);
        } else {
          this.seconds--;
        }
      }, 1000);
      const data = await this.$axios.post('/system/wechatController/sendMsg', {
        positionId: this.taskId,
        phone: this.form.phone,
        type: 3,
      });
      if (data.success) {
        this.form.smsCodeId = data.data;
      }
    },
    async handleSubmit() {
      this.submiting = true;
      const data = await this.$axios.post(
        '/tax/wechat/user/getOutContractUrl',
        {
          positionNo: this.positionNo,
          name: this.form.name.trim(),
          idNo: this.form.idNo.trim(),
          phone: this.form.phone,
          smsCode: this.form.smsCode,
          // smsCodeId: this.form.smsCodeId,
        }
      );
      if (data.success) {
        window.location.href = data.data;
      }
      this.submiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';

.Resign {
  min-height: calc(100vh - 0.46rem);
  padding-top: 0.46rem;
  background: #fff;

  .form {
    // padding: 0.36rem 0.42rem;

    .item {
      position: relative;
      margin-left: 0.16rem;
      padding: 0.13rem 0;

      &.van-hairline--bottom::after {
        border-color: $gray;
      }

      .label {
        height: 0.2rem;
        line-height: 0.2rem;
        margin-bottom: 0.05rem;
        color: #7d7f93;
      }

      .text {
        width: 3.48rem;
        height: 0.3rem;
        line-height: 0.3rem;
        padding: 0;
        border: none;
        color: #000;
        font-size: 0.18rem;

        &::placeholder {
          color: #b0b0b0;
        }

        &.code {
          width: 2.8rem;
        }
      }

      .icon-clear {
        position: absolute;
        top: 0.44rem;
        right: 0.16rem;
        color: #969799;
        font-size: 0.18rem;
      }

      .btn-send {
        position: absolute;
        top: 0.38rem;
        right: 0;
        height: 0.3rem;
        line-height: 0.3rem;
        padding: 0 0.16rem;
        border: none;
        color: $blue;
        font-size: 0.15rem;

        &.van-button--disabled {
          color: $gray;
          opacity: 1;
        }
      }

      .count-down {
        position: absolute;
        top: 0.38rem;
        right: 0;
        height: 0.3rem;
        line-height: 0.3rem;
        padding: 0 0.16rem;
        color: #adafb9;
        font-size: 0.15rem;
      }
    }

    .submit {
      width: 3.44rem;
      margin: 0.48rem auto 0;
      background: $blue;
      border-color: $blue;
      font-size: 0.17rem;
      font-weight: 500;
      box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);

      &.van-button--disabled {
        background: $gray;
        border-color: $gray;
        box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }
}
</style>
