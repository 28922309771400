<template>
  <div class="Result">
    <img src="../assets/icon_tick.png" alt="" />
    <p>认证完成</p>
  </div>
</template>

<script>
export default {
  name: 'Result',
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.Result {
  min-height: 100vh;
  background: #fff;
  padding-top: 40%;
  box-sizing: border-box;

  img {
    margin: 0 auto .24rem;
  }

  p {
    color: #666;
    font-size: 0.24rem;
    text-align: center;
  }
}
</style>
